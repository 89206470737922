var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-title"},[_c('div',{staticClass:"view-title__container"},[_c('h1',{staticClass:"view-title__new",class:{
        red: (_vm.newTitle == 'Agency' || _vm.newTitle == 'Agencia') && !_vm.home,
        blue: (_vm.newTitle == 'Studio' || _vm.newTitle == 'Estudio') && !_vm.home,
        green: _vm.newTitle == 'Powered' && !_vm.home,
      }},[_vm._l((_vm.newTitle),function(letter){return [_c('div',{key:letter.id,staticClass:"view-title__new-letter"},[_vm._v(" "+_vm._s(letter == " " ? " " : letter)+" ")])]})],2),_c('h1',{staticClass:"view-title__old",class:{
        red: (_vm.newTitle == 'Agency' || _vm.newTitle == 'Agencia') && !_vm.home,
        blue: (_vm.newTitle == 'Studio' || _vm.newTitle == 'Estudio') && !_vm.home,
        green: _vm.newTitle == 'Powered' && !_vm.home,
      }},[_vm._l((_vm.title),function(letter){return [_c('div',{key:letter.id,staticClass:"view-title__old-letter"},[_vm._v(" "+_vm._s(letter == " " ? " " : letter)+" ")])]})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"view-title__quote"},[_c('p',{staticClass:"view-title__quote-text",class:{
        green: _vm.newTitle == 'Powered' && !_vm.home,
      }},[_vm._v(" Powered by MI CASA Studios ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }